<template>
  <div class="page-right-icon">
    <div>
      <div id="pivotWrap" class="pivot-wrap flex-center-wrap flex-flow" @mouseover="handleOver" @mouseout="handleOut">
        <i class="icon-wechat"></i>
        <div class="f12 -mt15"></div>
      </div>
      <div class="left-show" v-if="isShow">
        <img src="../../assets/home/二维码.jpeg" alt="" />
        <div class="txt">免费领取合并教程</div>
      </div>
    </div>
    <!-- <div>
      <img
        src="../../assets/home/qwx.png"
        alt=""
        @mouseover="handleOver"
        @mouseout="handleOut"
      />
      <img
        src="../../assets/home/qy-code.png"
        alt=""
        class="left-show"
        v-if="isShow"
      />
    </div> -->
    <!-- <div>
        <img
          src="../../assets/home/QQ.png"
          alt=""
        />
      </div>
      <div>
        <img
          src="../../assets/home/zx-consult.png"
          alt=""
        />
      </div>
      <div>
        <img
          src="../../assets/home/phone.png"
          alt=""
        />
      </div> -->
  </div>
</template>

<script>
export default {
  name: 'rightIcon',
  components: {},
  props: {},
  data() {
    return {
      isShow: false
    }
  },
  created() {
    if (this.$route.path === '/home') {
      this.isShow = true;
    }
  },
  methods: {
    handleOver() {
      this.isShow = true
    },
    handleOut() {
      if (this.$route.path === '/home') {
        return;
      }
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.page-right-icon {
  position: fixed;
  top: 420px;
  right: 8px;
  z-index: 1644;

  >div {
    position: relative;
    /* width: 50px;
    height: 50px; */
    margin-bottom: 10px;
    border-radius: 4px;
    // overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .left-show {
      position: absolute;
      left: -180px;
      top: -42px;
      width: 195px;
      height: 200px;
      background: url('../../assets/home/right-icon-bg.png');
      background-size: 100% 100%;

      img {
        position: absolute;
        top: 20px;
        left: 38px;
        width: 120px;
        height: 120px;
      }

      .txt {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #373e4f;
        margin-top: 140px;
      }
    }
  }
}

.pivot-wrap {
  width: 70px;
  height: 70px;
  /* border: 1px solid #21ffa1; */
  /* background-color: #03e06e; */
  background: linear-gradient(0deg, #04AE0F, #13DC20);
  border-radius: 6px;
  color: #ffffff;
}
</style>
